<template>
  <!-- ローディングスピナー -->
  <Loading v-model:active="userInfo.status.inprogress" :can-cancel="false" color="var(--color-blue)" />
  <!-- ローディングスピナー ここまで -->
  <div class="m-3">
    <form @submit.prevent="updateUserInfo" class="align-items-center">
      <a href="#" class="arrow mobile-back d-sm-none mb-3" @click="$router.back()">戻る</a>
      <h2>
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-person-vcard" viewBox="0 0 16 16">
          <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5ZM9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8Zm1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Z"/>
          <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12V4Z"/>
        </svg>
        ユーザー情報
      </h2>
      <!-- エラーメッセージ -->
      <div v-if="userInfo.status.message" v-bind:class="[userInfo.status.isError ? 'alert-danger' : 'alert-success']" class="alert text-center" role="alert">
        {{ userInfo.status.message }}
      </div>
      <!-- エラーメッセージ ここまで -->
      <div class="container">
        <div class="row mb-2">
          <div class="col-sm-3">
            <label class="col-form-label">アカウントID</label>
          </div>
          <div class="col-sm-5">
            <label class="col-form-label">{{ userInfo.user.accountId }}</label>
          </div>
          <div class="col-sm-4">
            <span class="form-text">※変更できません</span>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-sm-3">
            <label class="col-form-label">氏名（漢字）</label>
          </div>
          <div class="col-sm-5">
            <input type="text" class="form-control" v-model="userInfo.user.name" required>
          </div>
          <div class="col-sm-4">
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-sm-3">
            <label class="col-form-label">氏名（カナ）</label>
          </div>
          <div class="col-sm-5">
            <input type="text" class="form-control" v-model="userInfo.user.nameKana"
              pattern="(?=.*?[\u30A1-\u30FC\uFF0E\uFF08\uFF09])[\u30A1-\u30FC\uFF0E\uFF08\uFF09\s]*" required>
          </div>
          <div class="col-sm-4">
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-sm-3">
            <label class="col-form-label">連絡先メールアドレス</label>
          </div>
          <div class="col-sm-5">
            <input type="email" class="form-control" v-model="userInfo.user.mailAddress"
              maxlength="100" required>
          </div>
          <div class="col-sm-4">
            <span class="form-text">※お知らせ配信先のメールアドレスです</span>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-sm-3">
            <label class="col-form-label">スタートアップページ</label>
          </div>
          <div class="col-sm-5">
            <select name="startupPage" id="startupPage" class="form-select" v-model.number="userInfo.user.startupPage" required>
              <option value="1" v-if="$store.getters.getOwnPermissions.canAccessEvidences">保管帳票一覧ページ</option>
              <option value="2" v-if="$store.getters.getOwnPermissions.canAccessReceiveInvoices">受領請求書一覧ページ</option>
              <option value="3" v-if="$store.getters.getOwnPermissions.canAccessDeliveryInvoices">発行請求書一覧ページ</option>
              <option value="4" v-if="$store.getters.getOwnPermissions.canAccessEntryAgentMenu">代行入力一覧ページ</option>
            </select>
          </div>
          <div class="col-sm-4">
            <span class="form-text">※サインイン後に表示する画面の選択です</span>
          </div>
        </div>

        <div v-if="$store.getters.getDefaultTenant?.isStorable" class="row mb-2 py-1 border">
          <div class="col-sm-3">
            <label class="col-form-label">アップロード時警告閾値</label>
          </div>
          <div class="col-sm-5">
            <p v-if="!$store.getters.getOwnPermissions.canUploadEvidence" class="text-body-secondary">
              アップロードファイルサイズ値の設定は帳票アップロード権限が必要です。
            </p>
            <div class="col-sm d-flex">
              <div class="form-group mb-1">
                <div :title="userInfo.user.fileSizeThreshold + 'バイト'">
                  <span v-if="userInfo.user.fileSizeThreshold === 0" class="text-nowrap">制限なし</span>
                  <span v-else>
                    {{ util.formatToByteString(userInfo?.user?.fileSizeThreshold) }}
                  </span>
                </div>
              </div>
              <div class="input-group mx-1 flex-nowrap">
                <span class="text-nowrap" style="font-size:.8rem">制限なし</span>
                <input type="range"
                  v-model.number="userInfo.user.fileSizeThreshold"
                  min="0" max="10485760" step="1048576"
                  class="form-range w-75 mx-2"
                  style="border: 0px;"
                />
                <span style="font-size:.8rem">10MB</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <span class="form-text">※モバイル端末で帳票をアップロードする際に、警告表示を行うファイルサイズの設定です</span>
          </div>
          <div class="col-sm-3">
            <label class="col-form-label">保管ユーザータグ</label>
          </div>
          <div class="col-sm-5 mb-2">
            <p v-if="!$store.getters.getOwnPermissions.canUploadEvidence" class="text-body-secondary">
              ユーザーデフォルトタグの設定は帳票アップロード権限が必要です。
            </p>
            <div class="ps-0 me-2 form-check form-check-inline" v-for="tag in userInfo.evidenceTags" :key="tag.id">
              <input type="checkbox" :id="'UiTg-' + tag.id" :value="tag.id" v-model="userInfo.user.tags">
              <label class="ps-1" :for="'UiTg-' + tag.id">
                <span class="badge" :style="'color:'+tag.foreColor+';background-color:'+tag.backColor">{{ tag.name }}</span>
              </label>
            </div>
          </div>
          <div class="col-sm-4">
            <span class="form-text">※帳票アップロード時に自動で付与するタグの設定です</span>
          </div>
        </div>

        <div v-if="$store.getters.getDefaultTenant?.isDeliverable" class="row mb-2 py-1 border">
          <div class="col-sm-3">
            <label class="col-form-label">発行ユーザータグ</label>
          </div>
          <div class="col-sm-5">
            <p v-if="!$store.getters.getOwnInfo.deliveryTags" class="m-2 text-center">設定されているタグはありません。</p>
            <div v-for="group in Array.from(new Set($store.getters.getOwnInfo.deliveryTags?.map(i => i.groupCode)))" :key="group">
              <div class="ps-0 me-2 form-check form-check-inline" v-for="tag in $store.getters.getOwnInfo.deliveryTags?.filter(i => i.groupCode === group)" :key="tag.id">
                <span class="badge" :style="'color:'+tag.foreColor+';background-color:'+tag.backColor">{{ tag.name }}</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <span class="form-text">
              ※設定変更はテナントユーザー管理者のみが可能です
            </span>
          </div>
        </div>

      </div><!-- container -->
      <div class="text-end">
        <button type="submit" class="btn btn-primary">更 新</button>
      </div>
    </form>
  </div>
</template>

<style scoped>
/* 画面サイズ576px以下の時 */
@media (max-width: 576px) {
  /* 戻るボタン */
  .arrow{
    position: relative;
    display: inline-block;
    padding: 0 0 0 16px;
    color: #000;
    vertical-align: middle;
    text-decoration: none;
    font-size: 17px;
    border-bottom: 1px solid #696969;
  }
  .arrow::before,
  .arrow::after{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: "";
    vertical-align: middle;
  }
  .mobile-back::before{
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    border: 1px solid #696969;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .mobile-back::after{
    left: 5px;
    width: 6px;
    height: 6px;
    border-top: 1px solid #696969;
    border-right: 1px solid #696969;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }

  .input-group {
    margin-right: 2px !important;
  }
  .input-group > span {
    font-size: .75rem !important;
  }
  .form-range {
    margin: 0 2px 0 !important;
  }
  .form-group {
    width: 25% !important;
  }
}

h2 {
  padding-bottom: 8px;
  color: var(--text-primary);
  font-size: 1.25rem;
  text-indent: 2px;
  border-bottom: 2px solid var(--theme-primary);
}

h2 svg {
  vertical-align: sub;
}

button.btn {
  padding: 2px 8px;
}

/* 入力フォーム */
form input,
form select,
form textarea {
  color: var(--text-primary);
  font-family: var(--font-family) !important;
  font-size: 100%;
  border: 1px solid var(--border-gray);
  background-color: var(--background-primary);
}

form select option {
  color: var(--text-primary);
  background-color: var(--background-primary);
}

.input-group {
  align-items: center;
}
.form-group {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  width: 23%;
}

</style>

<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";
import Loading from 'vue-loading-overlay';
import utilities from "@/services/utilities.js";
import useUserInfo from "@/composable/userInfo";

export default defineComponent({
  name: "InvoiceList",
  components: {
    Loading
  },
  setup() {
    // vuex::store
    const store = useStore();

    // 共通ユーティリティ読み込み
    const util = utilities;

    // userInfo.jsの関数を読み込み
    const { userInfo, initialize, updateUserInfo } = useUserInfo();

    // ユーザー情報取得状況をチェック
    if (store.state.fetched) {
      // ユーザー情報取得済の場合は初期化処理実行
      initialize();
    } else {
      // ユーザー情報未取得の場合は取得状況を監視
      store.watch(state => state.fetched, fetched => {
        if (fetched){
          initialize();
        }
      });
    }

    return {
      util,           // ユーティリティ
      userInfo,       // ユーザー情報オブジェクト
      initialize,     // フォーム初期化処理
      updateUserInfo, // ユーザー情報更新処理
    };
  }
});
</script>
