import { computed, reactive, watch } from 'vue';
import backend from '@/services/backendApi';

/**
 * 得意先マスター関連
 */
const useCustomer = () => {
    const customer = reactive({
        data: [],               // 得意先データ配列(生データ)
        list: [],               // 検索処理用得意先データ配列
        length: computed(() => customer.list.length),
        status: {
            get: {                          // 取得状況
                loaded: false,              // 取得済か否か
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            add: {                          // 追加状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            update: {                       // 追加状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            delete: {                       // 追加状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            updateEvidenceApprovalOption: { // 帳票承認設定更新状況 TODO: リファクタリング
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            updateIssueTagOption: {         // 発行タグ動作設定更新状況 TODO: リファクタリング
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            generateNotice: {               // お客様番号発行通知書生成状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            }
        },
        authCodeExpiration: undefined,      // ユーザー登録時認証コード有効期限
    });

    /** 新規追加用オブジェクト */
    const customerObject = reactive({
        tenantCode: "",             // 発行元テナントコード
        code: "",                   // 得意先コード
        zipCode: "",                // 郵便番号
        address1: "",               // 住所１
        address2: "",               // 住所２
        name1: "",                  // 名称１
        name2: "",                  // 名称２
        mailAddress: "",            // 連絡先メールアドレス
        phoneNo: "",                // 電話番号
        userName: "",               // 担当者名
        deadlineFlag: "",           // 締日フラグ
        invoiceType: "",            // 請求書タイプ
        currentMaxUsers: 0,         // 現最大ユーザー数
        newMaxUsers: 0,             // 新最大ユーザー数
        applyingDate: "",           // 新最大ユーザー数適用日
        isBrowsable: 0,             // サービスオプション（閲覧機能）
        isStorable: 0,              // サービスオプション（保管機能）
        isDeliverable: 0,           // サービスオプション（発行機能）
        enableOcr: 0,               // OCR実行有無
        entryAgentOption: 0,        // 代行入力オプション
        tags: [],                   // タグ
        isSpecial: 0,               // 特定顧客Flg
        approvalOption: 1,          // 承認オプション（1:チェック要）
        evidenceApprovalOption: 0,  // 帳票承認オプション（0：承認なし、1：一次承認、2：二次承認）
        issueSignUpMode: 1,         // 発行契約サインアップ方式（1:ユーザー登録時認証コード方式、2:ユーザー承認方式）
        issueTagOption: 1,          // 発行タグオプション（1:アクセス制御なし、2:アクセス制御あり）
    });

    /** 検索条件 */
    const searchCondition = reactive({
        code: "",                   // 得意先番号
        address: "",                // 住所
        name: "",                   // 得意先名称
        deadlineFlag: "",           // 締日フラグ
        tags: [],                   // タグ（グループ）
        isSpecial: "",              // 特定顧客フラグ
        state: "",                  // ステータス
        approvalOption: "",         // 承認オプション
        entryAgentOption: "",       // 代行入力オプション
        evidenceApprovalOption: "", // 帳票承認オプション
        dlday5: false,              // 5日締
        dlday10: false,             // 10日締
        dlday15: false,             // 15日締
        dlday20: false,             // 20日締
        dlday25: false,             // 25日締
        dldaylast: false,           // 末日締
        tate: false,                // 縦請求書
        yoko: false,                // 横請求書
        isBrowsable: false,         // 閲覧機能
        isStorable: false,          // 保管機能
        isDeliverable: false,       // 発行機能
        enableOcr: false,           // OCR実行有無
    });

    /** 呼び出し画面定義 */
    const callerPages = {
        ユーザー保守: 1, // 得意先マスターを含む
        承認設定保守: 2, // （不使用）
        権限保守: 3,
        取引先マスター保守: 4,
        帳票タグマスター保守: 5,
        帳票定義マスター保守: 6,
        グループマスター保守: 7,
        帳票種類変換マスター保守: 8,
        保管帳票一覧画面: 9,
        代行入力サポート情報マスター保守: 10,
        サービス連携設定マスター保守: 11,
        閲覧先管理画面: 12,
        サインアップ画面: 13,
        タグマスター保守: 14,
    }

    /** 外部連携サービス区分*/
    const externalServiceTypes = {
        1: "AIRS"
    }

    /** 外部連携サービス区分文字列 */
    const externalServiceTypeString = {
        1: "01:AIRS"
    }

    // 検索条件の変更を監視し、検索を実行させる
    watch(searchCondition, () => searchCustomerData());

    /** 得意先データを得意先コードの昇順にソートする処理 */
    const sortByCustomerCode = (a, b) => {
        if(a.customerCode > b.customerCode) return 1;
        if(a.customerCode < b.customerCode) return -1;
        return 0;
    }

    /**
     * サインアップ方式取得処理
     * @param {String} tenantCode 発行元コード
     * @param {Number} serviceType サービス区分（初期値: 0）
     * @param {Boolean} excludesDeleted 削除レコードを除外する場合はTrue。（初期値：True）
     * @param {Boolean} isContract 契約中顧客のみ取得する場合はTrue。（初期値：False）
     */
    const getSignupMethod = async (tenantCode, serviceType = 0, excludesDeleted = true, isContract = true) => {
        // メッセージ初期化、処理中フラグON
        customer.status.get.message = "";
        customer.status.get.inprogress = true;

        return await backend.getSignupMethod(tenantCode, serviceType, excludesDeleted, isContract)
            .then(response => {
                // 処理成功
                return Promise.resolve(response);
            }).catch(error => {
                // 処理失敗
                console.error(error);

                return Promise.reject(error);
            }).finally(() => {
                // 処理終了
            });
    }

    /**
     * 得意先マスター取得
     * @param {String} tenantCode 発行元コード（任意）
     * @param {String} customerCode 得意先コード（任意）
     * @param {Boolean} excludesDeleted 削除レコードを除外する場合はTrue。（初期値：True）
     * @param {Boolean} isContract 契約中顧客のみ取得する場合はTrue。（初期値：False）
     * @param {Number} callerPage 呼び出し元画面。(初期値：ユーザー保守)
     */
    const getCustomer = async (tenantCode, customerCode, excludesDeleted = true,
                               isContract = false, callerPage = callerPages.ユーザー保守) => {
        // メッセージ初期化、処理中フラグON
        customer.status.get.message = "";
        customer.status.get.inprogress = true;

        // データクリア
        clearCustomerList();

        await backend.getCustomer(tenantCode, customerCode, excludesDeleted, isContract, callerPage)
            .then(response => {
                // 得意先データがある場合は得意先データ配列にセット
                if (response.status !== 204) {
                    const result = response.data;
                    // 得意先コードの降順にソート
                    customer.data = result.contents.slice().sort(sortByCustomerCode);

                    // ユーザー登録時認証コード有効期限をセットする
                    customer.authCodeExpiration = result.authCodeExpiration ?? null;

                    // 取得成功
                    customer.status.get.success = true;
                }
            }).catch(error => {
                console.error(error);
                // 取得失敗
                customer.status.get.success = false;
                customer.status.get.message = error.message;
            }).finally(() => {
                // 読み込み済みに変更
                customer.status.get.loaded = true;
                // 処理中フラグOFF
                customer.status.get.inprogress = false;

                // 検索処理実施
                searchCustomerData();
            });
    }

    /**
     * 新規得意先マスター追加処理
     * @param {Object} customerObject 得意先マスターオブジェクト
     * @returns {Promise} Promiseオブジェクト
     */
    const addCustomer = async (customerObject) => {
        // メッセージ初期化・処理フラグON
        customer.status.add.message = "";
        customer.status.add.inprogress = true;

        return await backend.addCustomer(customerObject)
            .then(response => {
                // 処理成功
                customer.status.add.success = true;
                customer.status.add.message = response.data.message;

                return Promise.resolve(response);
            }).catch(error => {
                console.error(error);
                // 処理失敗
                customer.status.add.success = false;
                customer.status.add.message = error.message;

                return Promise.reject(error);
            }).finally(() => {
                // 処理終了
                customer.status.add.inprogress = false;
            });
    }

    /**
     * 得意先マスター更新処理
     * @param {Object} customerObject 得意先マスターオブジェクト
     * @returns {Promise} Promiseオブジェクト
     */
    const updateCustomer = async (customerObject) => {
        // メッセージ初期化・処理フラグON
        customer.status.update.message = "";
        customer.status.update.inprogress = true;

        return await backend.updateCustomer(customerObject)
            .then(response => {
                // 処理成功
                customer.status.update.success = true;
                customer.status.update.message = response.data.message;
            }).catch(error => {
                console.error(error);
                // 処理失敗
                customer.status.update.success = false;
                customer.status.update.message = error.message;
            }).finally(() => {
                // 処理終了
                customer.status.update.inprogress = false;
            });
    }

    /**
     * 得意先マスター削除処理
     * @param {String} tenantCode テナントコード
     * @param {String} code お客様番号
     * @returns {Promise} Promiseオブジェクト
     */
    const deleteCustomer = async (tenantCode, code) => {
        // メッセージ初期化・処理フラグON
        customer.status.delete.message = "";
        customer.status.delete.inprogress = true;

        return await backend.deleteCustomer(tenantCode, code)
            .then(response => {
                // 処理成功
                customer.status.delete.success = true;
                customer.status.delete.message = response.data.message;
            }).catch(error => {
                console.error(error);
                // 処理失敗
                customer.status.delete.success = false;
                customer.status.delete.message = error.message;
            }).finally(() => {
                // 処理終了
                customer.status.delete.inprogress = false;
            });
    };

    /**
     * テナントコード自動採番処理
     * @param {String} parentTenantCode 親テナントコード
     * @returns {Promise} Promiseオブジェクト
     */
    const autoNumberingTenantCode = async (parentTenantCode) => {
        return await backend.autoNumberingTenantCode(parentTenantCode).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    /**
     * 得意先マスター一覧CSVダウンロード処理
     * @param {String} tenantCode テナントコード
     * @returns {Promise} Promiseオブジェクト
     */
    const downloadCustomerListCsv = async (tenantCode) => {
        return await backend.downloadCustomerListCsv(tenantCode).then(response => {
            if (response.status === 200) {
                // 新しいタブで開く
                const link = document.createElement("a");                                 // リンク生成
                link.href = response.data.content;                                        // SASリンクをセット
                link.download = response.data.content.match(".+/(.+?)([?#;].*)?$")[1];    // ファイル名セット
                link.click();                                                             // クリック実行
            }
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    /**
     * 帳票承認設定更新処理
     * @param {Object} customerObject 得意先マスターオブジェクト
     * @returns {Promise} Promiseオブジェクト
     */
    const updateEvidenceApprovalOption = async (customerObject) => {
        // メッセージ初期化・処理フラグON
        customer.status.updateEvidenceApprovalOption.message = "";
        customer.status.updateEvidenceApprovalOption.inprogress = true;

        return await backend.updateEvidenceApprovalOption(customerObject)
            .then(response => {
                // 処理成功
                customer.status.updateEvidenceApprovalOption.success = true;
                customer.status.updateEvidenceApprovalOption.message = response.data.message;
            }).catch(error => {
                console.error(error);
                // 処理失敗
                customer.status.updateEvidenceApprovalOption.success = false;
                customer.status.updateEvidenceApprovalOption.message = error.message;
            }).finally(() => {
                // 処理終了
                customer.status.updateEvidenceApprovalOption.inprogress = false;
            });
    }

    /**
     * タグ動作設定更新処理
     * @param {Object} customerObject 得意先マスターオブジェクト
     * @returns {Promise} Promiseオブジェクト
     */
    const updateIssueTagOption = async (customerObject) => {
        // メッセージ初期化・処理フラグON
        customer.status.updateIssueTagOption.message = "";
        customer.status.updateIssueTagOption.inprogress = true;

        return await backend.updateIssueTagOption(customerObject)
            .then(response => {
                // 処理成功
                customer.status.updateIssueTagOption.success = true;
                customer.status.updateIssueTagOption.message = response.data.message;
            }).catch(error => {
                console.error(error);
                // 処理失敗
                customer.status.updateIssueTagOption.success = false;
                customer.status.updateIssueTagOption.message = error.message;
            }).finally(() => {
                // 処理終了
                customer.status.updateIssueTagOption.inprogress = false;
            });
    }

    /**
     * お客様番号発行通知書生成処理
     * @param {String} tenantCode テナントコード
     * @param {String} destinationTenantCode 送付先テナントコード
     * @param {Number} serviceType サービス区分
     * @param {Number} generateType 生成区分
     * @returns {Promise} Promiseオブジェクト
     */
    const generateNotice = async (tenantCode, destinationTenantCode, serviceType, generateType) => {
        customer.status.generateNotice.message = "";
        customer.status.generateNotice.inprogress = true;

        return await backend.generateCustomerNotice(tenantCode, destinationTenantCode, serviceType, generateType)
            .then(response => {
                // 処理成功
                customer.status.generateNotice.success = true;
                customer.status.generateNotice.message = response.data.message;

                // 新しいタブで開く
                const link = document.createElement("a");   // リンク生成
                link.href = response.data.content;          // SASリンクをセット
                link.target = "_blank";                     // 新しいタブで開く
                link.rel = "noopener noreferrer";           // セキュリティ対策
                link.click();                               // クリック実行

                return Promise.resolve(response);
            }).catch(error => {
                console.error(error);
                // 処理失敗
                customer.status.generateNotice.success = false;
                customer.status.generateNotice.message = error.message;

                return Promise.reject(error);
            }).finally(() => {
                // 処理終了
                customer.status.generateNotice.inprogress = false;
            });
    }

    /**
     * 切替可能テナント取得処理
     * @param {String} tenantCode 発行元コード（任意）
     * @param {String} customerCode 得意先コード（任意）
     * @param {Boolean} excludesDeleted 削除レコードを除外する場合はTrue。（初期値：True）
     * @param {Boolean} isContract 契約中顧客のみ取得する場合はTrue。（初期値：False）
     * @param {Number} callerPage 呼び出し元画面。(初期値：ユーザー保守)
     */
    const getSwitchableTenant = async (tenantCode = "", customerCode = "", excludesDeleted = true,
        isContract = false, callerPage = 0) => {
        // メッセージ初期化・処理フラグON
        customer.status.get.message = "";
        customer.status.get.inprogress = true;

        return await backend.getSwitchableTenant(tenantCode, customerCode, excludesDeleted, isContract, callerPage)
            .then(response => {
                // 切替可能テナントがある場合は切替可能テナント配列にセット
                if (response.status !== 204) {
                    const result = response.data;
                    // 得意先の降順にソート
                    customer.data = result.contents.slice().sort(sortByCustomerCode);

                    // 取得成功
                    customer.status.get.success = true;
                }
            }).catch(error => {
                console.error(error);
                // 処理失敗
                customer.status.get.success = false;
                customer.status.get.message = error.message;
            }).finally(() => {
                // 読み込み済みに変更
                customer.status.get.loaded = true;
                // 処理中フラグOFF
                customer.status.get.inprogress = false;

                // 検索処理実施
                searchCustomerData();
            });
    }

    /**
     * サービス連携設定マスター取得処理
     * @param {String} tenantCode テナントコード
     * @param {Number} serviceType サービス区分
     * @returns {Promise} Promiseオブジェクト
     */
    const getExternalServiceSettings = async (tenantCode, serviceType = null) => {
        //
        return await backend.getExternalServiceSettings(tenantCode, serviceType)
            .then(response => {
                // 処理成功
                return Promise.resolve(response);
            }).catch(error => {
                // 処理失敗
                console.error(error);

                return Promise.reject(error);
            }).finally(() => {
                // 処理終了
            });
    }

    /**
     * サービス連携設定マスター追加処理
     * @param {Object} externalServiceSettings サービス連携設定マスターオブジェクト
     * @returns {Promise} Promiseオブジェクト
     */
    const addExternalServiceSettings = async (externalServiceSettings) => {
        //
        return await backend.addExternalServiceSettings(externalServiceSettings)
            .then(response => {
                // 処理成功
                return Promise.resolve(response);
            }).catch(error => {
                // 処理失敗
                console.error(error);

                return Promise.reject(error);
            }).finally(() => {
                // 処理終了
            });
    }

    /**
     * サービス連携設定マスター更新処理
     * @param {Object} externalServiceSettings サービス連携設定マスターオブジェクト
     * @returns {Promise} Promiseオブジェクト
     */
    const updateExternalServiceSettings = async (externalServiceSettings) => {
        //
        return await backend.updateExternalServiceSettings(externalServiceSettings)
            .then(response => {
                // 処理成功
                return Promise.resolve(response);
            }).catch(error => {
                // 処理失敗
                console.error(error);

                return Promise.reject(error);
            }).finally(() => {
                // 処理終了
            });
    }

    /**
     * サービス連携設定マスター削除処理
     * @param {String} tenantCode テナントコード
     * @param {Number} serviceType サービス区分
     * @returns {Promise} Promiseオブジェクト
     */
    const deleteExternalServiceSettings = async (tenantCode, serviceType) => {
        //
        return await backend.deleteExternalServiceSettings(tenantCode, serviceType)
            .then(response => {
                // 処理成功
                return Promise.resolve(response);
            }).catch(error => {
                // 処理失敗
                console.error(error);

                return Promise.reject(error);
            }).finally(() => {
                // 処理終了
            });
    }

    /**
     * サービス連携コード変換マスター取得処理
     * @param {String} tenantCode テナントコード
     * @param {String} customerCode 得意先コード
     * @param {Number} serviceType サービス区分
     * @returns {Promise} Promiseオブジェクト
     */
    const getExternalServiceConversions = async (tenantCode, customerCode, serviceType = null) => {
        //
        return await backend.getExternalServiceConversions(tenantCode, customerCode, serviceType)
            .then(response => {
                // 処理成功
                return Promise.resolve(response);
            }).catch(error => {
                // 処理失敗
                console.error(error);

                return Promise.reject(error);
            }).finally(() => {
                // 処理終了
            });
    }

    /**
     * サービス連携コード変換マスター追加処理
     * @param {Object} externalServiceConversions サービス連携コード変換マスターオブジェクト
     * @returns {Promise} Promiseオブジェクト
     */
    const addExternalServiceConversions = async (externalServiceConversions) => {
        //
        return await backend.addExternalServiceConversions(externalServiceConversions)
            .then(response => {
                // 処理成功
                return Promise.resolve(response);
            }).catch(error => {
                // 処理失敗
                console.error(error);

                return Promise.reject(error);
            }).finally(() => {
                // 処理終了
            });
    }

    /**
     * サービス連携コード変換マスター更新処理
     * @param {Object} externalServiceConversions サービス連携コード変換マスターオブジェクト
     * @returns {Promise} Promiseオブジェクト
     */
    const updateExternalServiceConversions = async (externalServiceConversions) => {
        //
        return await backend.updateExternalServiceConversions(externalServiceConversions)
            .then(response => {
                // 処理成功
                return Promise.resolve(response);
            }).catch(error => {
                // 処理失敗
                console.error(error);

                return Promise.reject(error);
            }).finally(() => {
                // 処理終了
            });
    }

    /**
     * サービス連携コード変換マスター削除処理
     * @param {String} tenantCode テナントコード
     * @param {String} customerCode 得意先コード
     * @param {Number} serviceType サービス区分
     * @returns {Promise} Promiseオブジェクト
     */
    const deleteExternalServiceConversions = async (tenantCode, customerCode, serviceType) => {
        //
        return await backend.deleteExternalServiceConversions(tenantCode, customerCode, serviceType)
            .then(response => {
                // 処理成功
                return Promise.resolve(response);
            }).catch(error => {
                // 処理失敗
                console.error(error);

                return Promise.reject(error);
            }).finally(() => {
                // 処理終了
            });
    }

    /**
     * 得意先データ配列クリア処理
     */
    const clearCustomerList = async () => {
        customer.data = [];
        customer.list = [];
        customer.status.get.loaded = false;
        customer.status.get.success = false;
    }

    /**
     * 得意先データ検索処理
     */
    const searchCustomerData = () => {
        // 検索対象データを生データに戻す
        customer.list = customer.data.slice();

        // タグ(先頭で実施)
        if (searchCondition.tags.length !== 0) {
            /** カテゴリー毎の検索結果 */
            const results = [];

            /** 重複排除した、検索対象にセットされたタグのカテゴリーリスト */
            const tagGroups = Array.from(new Set(searchCondition.tags.map(t => Number(t.split(',')[0])))); // tag groups

            // タグカテゴリー毎にOR検索
            tagGroups.forEach(groupCode => {
                results.push(customer.list.filter( cust => {
                    if (!cust.tags) {
                        // タグ未設定の得意先は除外
                        return false;
                    }
                    // 検索条件に指定されたタグを含む得意先に絞り込む
                    return searchCondition.tags.filter(tag => tag.split(',')[0] == groupCode).some( tag => cust.tags.map(t => t.name).includes(tag.split(',')[1]))
                }));
            });

            // タグカテゴリー間でAND検索(各カテゴリー毎の結果と重複する得意先のみに絞り込む)
            results.forEach(result => {
                customer.list = customer.list.filter(cust => result.map(res => res.code).includes(cust.code));
            });

        }

        // 得意先番号
        if (searchCondition.code !== ""){
            customer.list = customer.list.filter(item =>
                item.code.toLowerCase().indexOf(searchCondition.code.toLowerCase()) !== -1
            );
        }

        // 住所
        if (searchCondition.address !== ""){
            customer.list = customer.list.filter(item =>
                // 住所１と住所２を結合
                ("" + item.address1 + item.address2).indexOf(searchCondition.address) !== -1
            );
        }

        // 得意先名称
        if (searchCondition.name !== ""){
            customer.list = customer.list.filter(item =>
                // 名称１と名称２を結合
                ("" + item.name1 + item.name2).indexOf(searchCondition.name) !== -1
            );
        }

        // 締日
        if ( searchCondition.dlday5 ||
             searchCondition.dlday10 ||
             searchCondition.dlday15 ||
             searchCondition.dlday20 ||
             searchCondition.dlday25 ||
             searchCondition.dldaylast) {
                customer.list = customer.list.filter(item => {
                    const deadline = item.deadlineFlag.split(",");
                    // 5日締
                    if (searchCondition.dlday5 && deadline[0] === "1") {
                        return true;
                    }
                    // 10日締
                    if (searchCondition.dlday10 && deadline[1] === "1") {
                        return true;
                    }
                    // 15日締
                    if (searchCondition.dlday15 && deadline[2] === "1") {
                        return true;
                    }
                    // 20日締
                    if (searchCondition.dlday20 && deadline[3] === "1") {
                        return true;
                    }
                    // 25日締
                    if (searchCondition.dlday25 && deadline[4] === "1") {
                        return true;
                    }
                    // 末日締
                    if (searchCondition.dldaylast && deadline[5] === "1") {
                        return true;
                    }
                    return false;
                });
            }

        // 請求書タイプ
        if (searchCondition.tate ||
            searchCondition.yoko) {
            customer.list = customer.list.filter(item => {
                const invoiceType = item.invoiceType.split(",");
                // 縦請求書
                if (searchCondition.tate && !searchCondition.yoko && invoiceType[0] === "1") {
                    return true;
                }
                // 横請求書
                if (searchCondition.yoko && !searchCondition.tate && invoiceType[1] === "1") {
                    return true;
                }
                // 縦請求書+横請求書
                if (searchCondition.tate && searchCondition.yoko && invoiceType[0] === "1" && invoiceType[1] === "1") {
                    return true;
                }
                return false;
            });
        }

        // サービスオプション（閲覧機能）
        if (searchCondition.isBrowsable == true) {
            customer.list = customer.list.filter(item =>
                item.isBrowsable === (searchCondition.isBrowsable === true)
            );
        }
        // サービスオプション（保管機能）
        if (searchCondition.isStorable == true) {
            customer.list = customer.list.filter(item =>
                item.isStorable === (searchCondition.isStorable === true)
            );
        }
        // サービスオプション（発行機能）
        if (searchCondition.isDeliverable == true) {
            customer.list = customer.list.filter(item =>
                item.isDeliverable === (searchCondition.isDeliverable === true)
            );
        }

        // OCR実行有無
        if (searchCondition.enableOcr == true) {
            customer.list = customer.list.filter(item =>
                item.enableOcr === (searchCondition.enableOcr === true)
            );
        }

        // 特定顧客フラグ
        if (searchCondition.isSpecial !== "") {
            customer.list = customer.list.filter(item =>
                item.isSpecial === (searchCondition.isSpecial === "1")
            );
        }

        // ステータス
        if (searchCondition.state !== "") {
            customer.list = customer.list.filter(item =>
                item.state === parseInt(searchCondition.state)
            );
        }

        // 承認オプション
        if (searchCondition.approvalOption !== "") {
            customer.list = customer.list.filter(item =>
                item.approvalOption === parseInt(searchCondition.approvalOption)
            );
        }

        // 代行入力オプション
        if (searchCondition.entryAgentOption !== "") {
            customer.list = customer.list.filter(item =>
                item.entryAgentOption === parseInt(searchCondition.entryAgentOption)
            );
        }

        // 帳票承認オプション
        if (searchCondition.evidenceApprovalOption !== "") {
            customer.list = customer.list.filter(item =>
                item.evidenceApprovalOption === parseInt(searchCondition.evidenceApprovalOption)
            );
        }
    }

    return {
        customer,
        customerObject,                             // 得意先オブジェクト
        searchCondition,                            // 検索条件
        callerPages,                                // 呼び出し画面定義
        externalServiceTypes,                       // 外部連携サービス区分
        externalServiceTypeString,                  // 外部連携サービス区分文字列
        getSignupMethod,                            // サインアップ方式取得処理
        getCustomer,                                // 得意先取得処理
        addCustomer,                                // 得意先追加処理
        updateCustomer,                             // 得意先更新処理
        deleteCustomer,                             // 得意先削除処理
        autoNumberingTenantCode,                    // テナントコード自動採番処理
        downloadCustomerListCsv,                    // 得意先一覧CSVダウンロード
        updateEvidenceApprovalOption,               // 帳票承認設定更新処理
        updateIssueTagOption,                       // タグ動作設定更新処理
        generateNotice,                             // お客様番号発行通知書生成処理
        getSwitchableTenant,                        // 切替可能テナント取得処理
        getExternalServiceSettings,                 // サービス連携設定マスター取得
        addExternalServiceSettings,                 // サービス連携設定マスター追加
        updateExternalServiceSettings,              // サービス連携設定マスター更新
        deleteExternalServiceSettings,              // サービス連携設定マスター削除
        getExternalServiceConversions,              // サービス連携コード変換マスター取得
        addExternalServiceConversions,              // サービス連携コード変換マスター追加
        updateExternalServiceConversions,           // サービス連携コード変換マスター更新
        deleteExternalServiceConversions,           // サービス連携コード変換マスター削除
        clearCustomerList,                          // 得意先データ配列クリア処理
    };
};

export default useCustomer;